@import './colors';

.ant-typography.ant-typography-primary {
  color: $primary;
}

.ant-typography-primary {
  color: $primary !important;
}

.ant-btn-success {
  color: #fff;
  background-color: $success;
  border-color: $success;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-btn-success:hover,
.ant-btn-success:focus {
  color: #fff;
  background-color: #3ee279;
  border-color: #3ee279;
}

.ant-btn-warning {
  color: #fff;
  background-color: $warning;
  border-color: $warning;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-btn-warning:hover,
.ant-btn-warning:focus {
  color: #fff;
  background-color: #efa45e;
  border-color: #efa45e;
}

.ant-slider {
  &.love .ant-slider-dot-active {
    border-color: #e33f44;
  }
  &.wealth .ant-slider-dot-active {
    border-color: #389671;
  }
  &.hobby .ant-slider-dot-active {
    border-color: #7a3f7a;
  }
  &.friendship .ant-slider-dot-active {
    border-color: #1d948d;
  }
  &.health .ant-slider-dot-active {
    border-color: #e4ba00;
  }
  &.work .ant-slider-dot-active {
    border-color: #e36814;
  }
}

// Overrides
.rtl-list-item {
  direction: rtl;

  .ant-list-item-extra {
    margin-left: auto !important;
    margin-right: 20px;
  }

  .ant-list-item-meta-description {
    direction: ltr;
  }
}
