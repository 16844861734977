// when change colors, please duplicate them to default.less
// Colors

$blue-6: #1890ff;
$green-6: #3dcf46;
$gold-6: #f59236;
$red-6: #f5222d;

$primary: $blue-6;
$success: $green-6;
$warning: $gold-6;
$danger: $red-6;

:export {
  primary: $primary;
  success: $success;
  warning: $warning;
  danger: $danger;
}
